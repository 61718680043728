import type { ReactElement } from 'react'

import CountrySelection from '../../CountrySelection'
import LegalFooterNavigation from './LegalFooterNavigation'
import Logistics from './Logistics'
import NewsletterForm from '../../templateComponents/NewsletterForm'
import Payments from './Payments'
import SocialMedia from './SocialMedia'

export default function Footer({ footerPages, legalPages, shop, t }: FooterThemeComponentProps): ReactElement {
  return (
    <footer className="section new-footer">
      <div className="wrapper footer">
        <NewsletterForm className="newsletter-form">
          <label className="newsletter-form-label" htmlFor="newsletter-form-field">
            {t('components.newsletterSubscriptionComponent.title')}
          </label>
          <div className="newsletter-form-register">
            <input
              className="newsletter-form-field"
              id="newsletter-form-field"
              name="email"
              type="email"
              required
              placeholder={t('components.newsletterSubscriptionComponent.emailAddressInputField.placeholder')}
            />
            <button className="newsletter-form-button" type="submit">
              <span>{t('components.newsletterSubscriptionComponent.submitButton.label')}</span>
            </button>
          </div>
        </NewsletterForm>
        <div className={`footer-${shop.userSettings.footerLayout.variant}`}>
          <LegalFooterNavigation {...{ legalPages, footerPages }} />
          <div>
            <Payments paymentLogos={shop.userSettings.paymentLogos} t={t} />
            <Logistics shippingLogos={shop.userSettings.shippingLogos} />
          </div>
          <SocialMedia socialNetworks={shop.userSettings.socialNetworks} />
        </div>
        {shop.userSettings.copyright?.active && (
          <div className="footer-copyright">
            <p>{shop.userSettings.copyright.text}</p>
          </div>
        )}
      </div>
      <div className="country-selection">
        <CountrySelection>
          {(shopCountries, setSellingCountry, shopSellingCountry) => (
            <div className="delivery-country">
              <label htmlFor="country-selection">
                {t('components.bottomBar.footerSettings.countrySelection.label')}
              </label>
              <div className="footer-switch">
                <select
                  id="country-selection"
                  value={shopSellingCountry || ''}
                  onChange={({ target }) => setSellingCountry(Number(target.value))}
                >
                  {shopCountries.map(({ countryId, name }) => (
                    <option key={countryId} value={countryId}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </CountrySelection>
      </div>
      <div className="country-selection">
        <CountrySelection>
          {(shopCountries, setSellingCountry, shopSellingCountry) => (
            <div className="delivery-country">
              <label htmlFor="country-selection">
                {t('components.bottomBar.footerSettings.countrySelection.label')}
              </label>
              <div className="footer-switch">
                <select
                  id="country-selection"
                  value={shopSellingCountry || ''}
                  onChange={({ target }) => setSellingCountry(Number(target.value))}
                >
                  {shopCountries.map(({ countryId, name }) => (
                    <option key={countryId} value={countryId}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </CountrySelection>
      </div>
    </footer>
  )
}
