import { Component } from 'react'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

import ErrorBoundary from '../../../../ErrorBoundary'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

const RichTextEditor = loadable(() => import(/* webpackChunkName: "editor" */ './RichTextEditor'), { ssr: false })

export class TextPluginRaw extends Component {
  static propTypes = {
    config: PropTypes.object,
    editorView: PropTypes.bool.isRequired,
    editorMode: PropTypes.oneOf(['view', 'edit']).isRequired,
    data: ImmutablePropTypes.map.isRequired,
    onSave: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDataChange: PropTypes.func.isRequired,
    setRef: PropTypes.func,
    t: PropTypes.func.isRequired,
  }

  static get defaultProps() {
    return {
      data: Immutable.fromJS({
        content: '',
      }),
    }
  }

  static get actionBarButtons() {
    return {
      edit: true,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !Immutable.is(this.props.data, nextProps.data)
  }

  onChange = (html) => {
    const newData = this.props.data.set('content', html)
    this.props.onDataChange(newData)
    this.props.onSave(newData)
  }

  onFocus = () => {
    this.props.onEdit()
  }

  render() {
    return (
      <div className="dali-plugin-text">
        {this.props.editorView ? this.renderEditorView() : this.renderDefaultView()}
      </div>
    )
  }

  renderDefaultView() {
    return (
      <div className="dali-plugin-text-content" dangerouslySetInnerHTML={{ __html: this.props.data.get('content') }} />
    )
  }

  renderErrorView() {
    return (
      <>
        <p className="dali-plugin-text-error-message">
          {this.props.t('components.richTextComponent.brokenRichTextEditorMessage')}
          <br />
          <code>{this.props.data.get('content')}</code>
        </p>
        {this.renderDefaultView()}
      </>
    )
  }

  renderEditorView() {
    return (
      <ErrorBoundary fallback={this.renderErrorView()}>
        <RichTextEditor
          /* Force a remount whenever the content changes. Avoids syncing-work inside. */
          key={this.props.data.get('content') || ''}
          fallback={this.renderDefaultView()}
          html={this.props.data.get('content') || ''}
          onChange={this.onChange}
          onFocus={this.onFocus}
          setRef={this.props.setRef}
          isHeadline={this.props.data.get('isHeadline')}
        />
      </ErrorBoundary>
    )
  }
}

export default compose(withI18n('interface'), translate())(TextPluginRaw)
