import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { useImageColorSettings } from '../../../../../utils/hooks/colorSettings'

function CtaTextOverlay({ textSettings, image }) {
  const { headline, text, buttontext, buttonenabled } = textSettings
  const { textColor, buttonColor, backgroundColor, overlayOpacity } = useImageColorSettings(image)

  const isButtonRenders = buttontext && buttonenabled

  if (!(headline || text || isButtonRenders)) {
    return null
  }

  return (
    <div
      className="dali-teaser"
      data-overlay-color={backgroundColor.get('name')}
      data-text-color={textColor.get('name')}
      data-button-color={buttonColor.get('name')}
      style={{ '--opacity': overlayOpacity / 100 }}
    >
      <div className="dali-teaser-inner">
        {(headline || text) && (
          <div className="dali-teaser-inner-content">
            {headline && <h2 className="dali-teaser-inner-content-headline">{headline}</h2>}
            {text && <p className="dali-teaser-inner-content-text">{text}</p>}
          </div>
        )}
        {isButtonRenders && (
          <button type="button" className="dali-teaser-inner-button">
            {buttontext}
          </button>
        )}
      </div>
    </div>
  )
}

CtaTextOverlay.propTypes = {
  textSettings: PropTypes.shape({
    headline: PropTypes.string,
    text: PropTypes.string,
    buttontext: PropTypes.string,
    buttonenabled: PropTypes.bool,
  }),
  image: ImmutablePropTypes.map.isRequired,
}

export default CtaTextOverlay
