import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { useRef, useState } from 'react'

import { allowedMimeTypes } from '../templateComponents/Workspace/daliConfig'

const maxZoomFactor = 5
const zoomStep = 0.1

// Aspect ratio selection to be done with https://epages.atlassian.net/browse/EPUI-2686.
// With this follow-up ticket, a "Circle" option is offered as well, which is not
// an aspect ratio but a certain shape with a fixed aspect ratio (frame?).
type ImageEditorMenuProps = Readonly<{
  t: TranslateProps['t']
  referenceElement: HTMLElement | null
  disabled?: boolean
  aspectRatio?: number
  aspectRatioOptions: Record<string, number>
  zoomFactor: number
  onZoomFactorChange: (zoomFactor: number) => void
  onAspectRatioChange: (aspectRatio: number) => void
  onFileChange: (file: File) => void
  onCancel: () => void
  onSave: () => void
}>

/**
 * Image editor menu with image editing options. This component is used in the
 * image editor component to allow users to change the image, zoom level,
 * aspect ratio, and save or cancel the changes.
 *
 * @param t Scoped translation function
 * @param referenceElement The reference element to position the menu
 * @param disabled Whether the menu is disabled (e.g. during pending state)
 * @param aspectRatio The current image aspect ratio
 * @param aspectRatioOptions The available aspect ratio options to choose from
 * @param zoomFactor The current zoom factor
 * @param onZoomFactorChange Callback to handle changes to the zoom factor
 * @param onAspectRatioChange Callback to handle aspect ratio selection
 * @param onFileChange Callback to handle changes to the image file
 * @param onCancel Callback to handle canceling the editing
 * @param onSave Callback to handle saving the changes
 */
export function ImageEditorMenu({
  t,
  referenceElement,
  disabled,
  aspectRatio,
  aspectRatioOptions,
  zoomFactor,
  onZoomFactorChange,
  onAspectRatioChange,
  onFileChange,
  onCancel,
  onSave,
}: ImageEditorMenuProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLFieldSetElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 30],
        },
      },
      {
        name: 'flip',
        enabled: false,
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 5,
        },
      },
    ],
  })

  function handleZoom(value: number) {
    // Round to one decimal place to avoid floating point errors
    const newZoomFactor = parseFloat(value.toFixed(1))
    onZoomFactorChange(newZoomFactor)
  }

  return createPortal(
    <fieldset
      data-ep-editor
      className="dali-plugin-image-editor-menu"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      disabled={disabled}
    >
      <legend className="visually-hidden">{t('imageEditOptions.accessibilityLabel')}</legend>
      <div className="dali-range-input-wrapper">
        <button
          title={t('imageZoomOutButton.accessibilityLabel')}
          type="button"
          onClick={() => handleZoom(zoomFactor - zoomStep)}
          disabled={zoomFactor === 1}
        ></button>
        <label>
          <span className="visually-hidden">{t('imageZoomLevelSlider.accessibilityLabel')}</span>
          <input
            type="range"
            className="dali-range-input"
            min={1}
            max={maxZoomFactor}
            style={{ '--min': 1, '--max': maxZoomFactor, '--val': zoomFactor }}
            step={zoomStep}
            value={zoomFactor}
            onChange={(event) => handleZoom(parseFloat(event.target.value))}
          />
        </label>
        <button
          title={t('imageZoomInButton.accessibilityLabel')}
          type="button"
          onClick={() => handleZoom(zoomFactor + zoomStep)}
          disabled={zoomFactor === maxZoomFactor}
        ></button>
      </div>
      <div className="dali-plugin-image-editor-menu-seperator"></div>
      <div>
        <button
          title={t('changeImageButton.label')}
          type="button"
          className="dali-plugin-image-button-change"
          onClick={() => fileInputRef.current?.click()}
        ></button>
        <input
          ref={fileInputRef}
          type="file"
          hidden
          accept={allowedMimeTypes.join(',')}
          onChange={(event) => {
            const file = event.target.files?.[0]
            if (file) onFileChange(file)
          }}
        />
      </div>
      <div>
        <label>
          Aspect ratio:{' '}
          <select
            defaultValue={aspectRatio}
            onChange={(event) => {
              onAspectRatioChange(parseFloat(event.target.value))
            }}
          >
            {Object.entries(aspectRatioOptions).map(([label, value]) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <button type="button" className="ep-modal-button-cancel" onClick={onCancel}>
          {t('cancelButton.label')}
        </button>
        <button type="button" className="ep-modal-button-save" onClick={onSave}>
          {t('saveButton.label')}
        </button>
      </div>
    </fieldset>,
    document.body,
  )
}
